<template>
  <div class="register-page">
    <div class="section has-background-primary has-text-white">
      <div class="container is-tablet p-t-10x">
        <h1 class="title is-size-3 m-b has-text-white">
          {{ $t('views.static.Feedback.title') }}
        </h1>
        <p>
          Your feedback is very important to us! <br>
          Not only does it help us improve but we can also help you with extra product needs.
        </p>
      </div>
    </div>
    <div class="section">
      <div class="container is-tablet p-t-4x">
        <el-form
          ref="localForm"
          :model="localForm"
          :rules="rules"
          class="columns is-multiline"
          label-position="top"
          status-icon>
          <el-form-item
            :label="$t('views.static.form.feedbackType.label')"
            class="column is-12"
            prop="feedbackType">
            <el-select
              v-model="localForm.feedbackType"
              :placeholder="$t('views.static.form.feedbackType.placeholder')"
              style="width: 100%;">
              <el-option
                v-for="it in feedbackTypeOptions"
                :key="it.value"
                :value="it.value"
                :label="it.label" />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="showSubject"
            :label="subjectObj.label"
            class="column is-12"
            prop="subject">
            <el-select
              v-model="localForm.subject"
              :placeholder="$t('views.static.form.feedbackType.placeholder')"
              style="width: 100%;">
              <el-option
                v-for="it in subjectObj.options"
                :key="it.value"
                :value="it.value"
                :label="it.label" />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('views.static.form.message.label')"
            class="column is-12"
            prop="message">
            <el-input
              v-model="localForm.message"
              :autosize="{ minRows: 4, maxRows: 6}"
              :placeholder="$t('views.static.form.message.placeholder')"
              type="textarea" />
          </el-form-item>
          <el-form-item
            :label="$t('views.customer.form.first-name.label')"
            class="column is-6"
            prop="firstName">
            <el-input
              v-model="localForm.firstName"
              :placeholder="$t('views.customer.form.first-name.placeholder')"
              autocomplete="firstName" />
          </el-form-item>
          <el-form-item
            :label="$t('views.customer.form.last-name.label')"
            class="column is-6"
            prop="lastName">
            <el-input
              v-model="localForm.lastName"
              :placeholder="$t('views.customer.form.last-name.placeholder')"
              autocomplete="lastName" />
          </el-form-item>
          <el-form-item
            :label="$t('views.customer.form.email.label')"
            class="column is-12"
            prop="email">
            <el-input
              v-model.trim="localForm.email"
              :placeholder="$t('views.customer.form.email.placeholder')"
              type="email"
              autocomplete="email" />
          </el-form-item>
          <div class="column is-12 recaptcha-column">
            <Recaptcha
              ref="recaptcha"
              @verify="handleRecaptchaVerify"
              @error="handleRecaptchaError" />
          </div>
          <div
            v-if="error"
            class="column is-12">
            <el-alert
              :title="error"
              :closable="false"
              type="error" />
          </div>
          <div class="column is-12 has-text-right">
            <el-button
              :loading="isSubmitting"
              class="button-secondary"
              style="min-width: 155px;"
              @click="handleSubmit">
              {{ $t('views.customer.form.submit') }}
            </el-button>
          </div>
          <div class="column is-12">
            <RecaptchaTerms />
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Recaptcha from '@/components/Recaptcha'
import RecaptchaTerms from '@/components/RecaptchaTerms'
import mixHead from '@/mixins/mixHead'

export default {
  name: 'Feedback',
  components: { RecaptchaTerms, Recaptcha },
  mixins: [mixHead],
  props: {
    type: String,
    hotelId: String
  },
  i18n: {
    messages: {
      'en-US': {
        'success': 'Feedback has been sent.',
        'error': 'Failed to send feedback.'
      }
    }
  },
  data () {
    return {
      seo: {
        title: 'Feedback | eBEDS',
        description: 'Share with us your feedback and help us to improve.',
        url: `${this.$store.state.baseUrl}/feedback`,
        image: `${this.$store.state.baseUrl}/og_image_register.jpg`
      },
      isSubmitting: false,
      error: '',
      localForm: {
        feedbackType: undefined,
        subject: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        message: undefined
      },
      rules: {
        firstName: [
          // {
          //   required: true,
          //   message: this.$t('views.customer.form.first-name.required'),
          //   trigger: [
          //     'change', 'blur'
          //   ]
          // }
        ],
        lastName: [
          // {
          //   required: true,
          //   message: this.$t('views.customer.form.last-name.required'),
          //   trigger: [
          //     'change', 'blur'
          //   ]
          // }
        ],
        email: [
          {
            type: 'email',
            message: this.$t('views.customer.form.email.invalid'),
            trigger: [
              'change', 'blur', 'input'
            ]
          },
          {
            required: true,
            message: this.$t('views.customer.form.email.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        feedbackType: [
          {
            required: true,
            message: this.$t('views.static.form.feedbackType.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        subject: [
          {
            required: true,
            message: this.$t('views.static.form.subject.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      },
      feedbackTypeOptions: [
        // {
        //   value: 'bookingIssue',
        //   label: this.$t('views.static.Booking issue')
        // },
        {
          value: 'wrongInformation',
          label: this.$t('views.static.Wrong information')
        },
        {
          value: 'productImprovement',
          label: this.$t('views.static.Product improvement')
        },
        {
          value: 'generalFeedback',
          label: this.$t('views.static.General feedback')
        }
      ],
      subjects: {
        // 'bookingIssue': {
        //   label: this.$t('views.static.What is the issue'),
        //   options: [
        //     {
        //       value: 'Make booking',
        //       label: this.$t('views.static.Make booking')
        //     },
        //     {
        //       value: 'Cancel booking',
        //       label: this.$t('views.static.Cancel booking')
        //     },
        //     {
        //       value: 'Payment by credit card',
        //       label: this.$t('views.static.Payment by credit card')
        //     },
        //     {
        //       value: 'Payment by balance',
        //       label: this.$t('views.static.Payment by balance')
        //     },
        //     {
        //       value: 'Booking for large number',
        //       label: this.$t('views.static.Booking for large number')
        //     },
        //     {
        //       value: 'Other',
        //       label: this.$t('views.static.Other')
        //     }
        //   ]
        // },
        'wrongInformation': {
          label: this.$t('views.static.What is wrong'),
          options: [
            {
              value: 'Wrong hotel name',
              label: this.$t('views.static.Wrong hotel name')
            },
            {
              value: 'Wrong star',
              label: this.$t('views.static.Wrong star')
            },
            {
              value: 'Wrong address',
              label: this.$t('views.static.Wrong address')
            },
            {
              value: 'Wrong phone number',
              label: this.$t('views.static.Wrong phone number')
            },
            {
              value: 'Other',
              label: this.$t('views.static.Other')
            }
          ]
        },
        'productImprovement': {
          label: this.$t('views.static.What can be improved'),
          options: [
            {
              value: 'Agent Hub Suggestions',
              label: 'Agent Hub Suggestions'
            },
            {
              value: 'Booking process',
              label: this.$t('views.static.Booking process')
            },
            {
              value: 'Payment process',
              label: this.$t('views.static.Payment process')
            },
            {
              value: 'Account management',
              label: this.$t('views.static.Account management')
            },
            {
              value: 'Other',
              label: this.$t('views.static.Other')
            }
          ]
        }
      }
    }
  },
  computed: {
    subjectObj () {
      let _obj = {
        label: undefined,
        options: []
      }

      if (this.showSubject) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.localForm.subject = undefined
        _obj = { ...this.subjects[this.localForm.feedbackType] }
      }
      return _obj
    },
    showSubject () {
      return this.localForm.feedbackType && this.localForm.feedbackType !== 'generalFeedback'
    }
  },
  created () {
    if (this.type && ['bookingIssue', 'wrongInformation', 'productImprovement', 'generalFeedback'].includes(this.type)) {
      this.localForm.feedbackType = this.type
    }

    if (this.hotelId) {
      this.localForm.message = `Hotel ID: ${this.hotelId}`
    }

    this.localForm.firstName = this.$store.getters['user/firstName']
    this.localForm.lastName = this.$store.getters['user/lastName']
    this.localForm.email = this.$store.getters['user/email']
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.error = ''
      this.$refs.localForm.validate((valid) => {
        if (valid) {
          this.isSubmitting = true
          this.$refs.recaptcha.execute()
        }
      })
    },
    handleRecaptchaVerify (token) {
      return this.$store.dispatch('user/sendFeedback', {
        data: this.localForm,
        headers: {
          'x-recaptcha-token': token,
          'x-recaptcha-ver': 'V2_INVISIBLE'
        }
      }).then(
        (resp) => {
          this.$refs.recaptcha.reset()
          this.isSubmitting = false
          if (resp.ok) {
            this.$message({
              message: this.$t('success'),
              type: 'success'
            })
            this.$refs.localForm.resetFields()
          } else {
            this.$message({
              message: this.$t('error'),
              type: 'success'
            })
          }
        }
      ).catch(err => {
        this.$refs.recaptcha.reset()
        this.isSubmitting = false
        this.error = err.message || (err.error && err.error.message) || ''
      })
    },
    handleRecaptchaError (message) {
      this.error = message
      this.isLoading = false
      this.$refs.recaptcha.reset()
    }
  }
}
</script>

<style scoped>
</style>
