<template>
  <div class="ecreds-page">
    <div class="section is-vertically-large has-background-primary has-text-white">
      <div
        class="container is-tablet has-text-centered"
        style="padding-bottom: 10px;padding-top: 10px;">
        <h1 class="title is-size-3 m-b  has-text-white">
          What are eCREDS?
        </h1>
        <p>eCREDS is the loyalty programme offered by eBEDS. All travel agents who sign up to eBEDS, and are verified by our team, will automatically start earning eCREDS from their very first booking. eCREDS rewards you with each booking you make through our booking platforms.</p>
      </div>
    </div>
    <div class="section">
      <div class="container is-tablet p-t-4x">
        <ul class="ecreds-list">
          <li class="blue">
            <i class="ecreds-icon ecreds-icon-0" />
            <div class="ecreds-item">
              <h5 class="has-text-primary has-text-weight-bold is-size-4">
                How much?
              </h5>
              <p>2% of the total Net rate of every booking—confirmed, paid, and travelled.</p>
            </div>
          </li>
          <li class="blue">
            <i class="ecreds-icon ecreds-icon-1" />
            <div class="ecreds-item">
              <h5 class="has-text-primary has-text-weight-bold is-size-4">
                How to earn
              </h5>
              <ul class="m-b-2x">
                <li>Book your accommodation through eBEDS.</li>
                <li>Book your accommodation through our Booking.com plugin.</li>
                <li>Book your car rental through our Rentalcars.com plugin.</li>
              </ul>
              <p><i>Do I need to do anything to earn? Not really, just make sure to follow the booking instructions which we will send to you once your account is verified.</i></p>
            </div>
          </li>
          <li class="yellow">
            <i class="ecreds-icon ecreds-icon-2" />
            <div class="ecreds-item">
              <h5 class="has-text-primary has-text-weight-bold is-size-4">
                How to keep track
              </h5>
              <ul>
                <li>All confirmed, paid, and travelled bookings will be allocated eCREDS.</li>
                <li>This is usually allocated 1-2 months after checkout and will be paid out into your eBEDS Wallet. You can see these transactions by clicking on your name on the top right > My Account > Wallet.</li>
                <li>We will also send you a monthly eCREDS statement.</li>
              </ul>
            </div>
          </li>
          <li class="orange">
            <i class="ecreds-icon ecreds-icon-3" />
            <div class="ecreds-item">
              <h5 class="has-text-primary has-text-weight-bold is-size-4">
                How to spend
              </h5>

              <ul class="m-b-2x">
                <li>As eCREDS are added to your eBEDS Wallet, you can easily spend these on any future bookings (for yourself, as an incentive, or a discount for a client).</li>
                <li>Some eCREDS can be converted to vouchers from your favourite online store or supermarket*.</li>
              </ul>
              <p class="m-b-2x">
                <i>Do I need to do anything to spend? Nope, you can allocate funds from your Wallet when completing a booking.</i>
              </p>
              <p><strong>*Note*</strong> - Vouchers depend on the country, retailer, and amount. Please ask your account manager for more information.</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import mixHead from '@/mixins/mixHead'
export default {
  name: 'Ecreds',
  mixins: [mixHead],
  data () {
    return {
      seo: {
        title: 'eCREDS | eBEDS',
        description: 'eCREDS is the loyalty programme offered by eBEDS. All travel agents who sign up to eBEDS, and are verified by our team, will automatically start earning eCREDS from their very first booking. eCREDS rewards you with each booking you make through our booking platforms.',
        url: `${this.$store.state.baseUrl}/ecreds`,
        image: `${this.$store.state.baseUrl}/og_image_logo.jpg`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/bulma-variables";

  .ecreds-list {
    position: relative;
    //margin-top: 60px;
    margin-bottom: 3rem;

    &:before {
      position: absolute;
      left: 8px;
      top: 0;
      display: block;
      content: '';
      width: 4px;
      height: 100%;
      background-color: $color-8;
    }

    > li {
      position: relative;
      padding-left: 132px;
      padding-bottom: 60px;
      &:last-child {
        padding-bottom: 0;
      }

      &:before {
        left: 0;
        top: 30px;
        display: block;
        content: '';
        border: 3px solid $color-8;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        background-color: #DDE5ED;
      }
    }
  }

  .ecreds-icon {
    display: block;
    width: 70px;
    height: 70px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: absolute;
    top: 5px;
    left: 32px;
  }

  .ecreds-icon-0 {
    background-image: url("../../assets/home-img-2.svg");
  }
  .ecreds-icon-1 {
    background-image: url("../../assets/icon-laptop.svg");
  }

  .ecreds-icon-2 {
    background-image: url("../../assets/icon-zoom.svg");
  }

  .ecreds-icon-3 {
    background-image: url("../../assets/home-img-3.svg");
  }
  .ecreds-item ul {
    list-style: disc;
    padding-left: 1.5em;
  }

</style>
