<template>
  <div class="faq-page">
    <div
      class="section has-background-primary"
      style="padding-top: 80px;padding-bottom: 80px;">
      <div class="container is-mobile">
        <h1 class="is-size-3 has-text-weight-bold has-text-white has-text-centered m-b-5x">
          {{ $t('title') }}
        </h1>
        <el-form
          ref="localForm"
          :model="localForm"
          class="columns is-mini-gap"
          label-position="top"
          status-icon>
          <el-form-item
            class="column"
            prop="search">
            <el-autocomplete
              v-model="localForm.search"
              :placeholder="$t('placeholder')"
              :fetch-suggestions="querySearch"
              style="width: 100%;"
              clearable
              @select="handleSelect">
              <template slot-scope="{ item }">
                <div class="faq-suggestion-item">
                  {{ item.label }}
                </div>
              </template>
            </el-autocomplete>
          </el-form-item>
          <div class="column is-narrow">
            <el-button
              type="primary"
              style="min-width: 110px;"
              @click="handleSubmit">
              {{ $t('views.share.search') }}
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="section-has-sidebar">
      <aside class="sidebar">
        <div class="sidebar-inner">
          <ul class="sidebar-menu">
            <li
              v-for="(value, key) in faq"
              :key="key"
              :class="{ active: activeSectionKey === key }">
              <a @click="changeActiveSection(key)">{{ value.title }}</a>
            </li>
          </ul>
        </div>
      </aside>
      <main class="main-content">
        <div class="main-content-inner">
          <h1 class="is-size-4 has-text-primary has-text-weight-bold m-b-3x">
            {{ activeTab.title }}
          </h1>
          <el-collapse
            v-model="activeName"
            accordion>
            <el-collapse-item
              v-for="(item,index) in activeTab.items"
              :key="activeSectionKey+'-'+index"
              :title="item.header"
              :name="activeSectionKey+'-'+index"
              @click.native="setHash(item.slug)">
              <div v-html="item.content" />
            </el-collapse-item>
          </el-collapse>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import findIndex from 'lodash/findIndex'

import mixHead from '@/mixins/mixHead'

export default {
  name: 'Faq',
  // Static pages have a lot of text,use component based localization
  i18n: {
    messages: {
      'en-US': {
        'title': 'How can we help you?',
        'placeholder': 'Type your question'
      },
      'zh-CN': {
        'title': '我们能为您做些什么？',
        'placeholder': '输入您的问题'
      }
    }
  },
  mixins: [mixHead],
  data () {
    return {
      seo: {
        title: 'Help | eBEDS',
        description: 'Have a question about your booking? Our customer service agents are available 24/7 by email or call us on our toll-free hotline.',
        url: `${this.$store.state.baseUrl}/faq`,
        image: `${this.$store.state.baseUrl}/og_image_logo.jpg`
      },
      localForm: {
        search: undefined
      },
      activeName: 'getting-started-0',
      faq: [],
      activeSectionKey: 'getting-started',
      searchResult: undefined
    }
  },
  computed: {
    activeTab () {
      return this.faq[this.activeSectionKey]
    },
    suggestions () {
      const _suggestions = []

      Object.entries(this.faq).forEach((entry, i) => {
        const [ parent, tab ] = entry

        tab.items.forEach((suggestion, j) => {
          _suggestions.push({
            value: suggestion.header,
            label: suggestion.header,
            slug: suggestion.slug,
            parent,
            tabIndex: i,
            suggestionIndex: j
          })
        })
      })
      return _suggestions
    }
  },
  created () {
    this.faq = {
      'getting-started': {
        title: 'Get Started',
        items: [
          {
            header: 'Am I eligible for an account?',
            content: '<p>Anyone who is in the travel agent business can register with eBEDS.  Simply provide a few details of you and your company to get started. To register, click <a href="/register" target="_blank" class="has-text-secondary">here</a>.</p>',
            slug: 'am-i-eligible-for-an-account'
          },
          {
            header: 'How do I create an account?',
            content: '<ul><li>Step 1: On the homepage, click <b>Register</b></li><li>Step 2: Fill in the registration form and choose your password and click on submit</li><li>Step 3: You will receive an email to confirm your email address (If the email does not arrive in your inbox, check your spam folder). Click the link in the email.</li><li>Step 4: Once the email address is confirmed, you can log in and use your account.</li></ul>',
            slug: 'how-do-i-create-an-account'
          },
          {
            header: 'Can I sign up a new user with the same email as a current user?',
            content: '<p>No, each user must have a unique email address.</p></p>',
            slug: 'sign-up-with-the-same-email'
          }
        ]
      },
      booking: {
        title: 'Booking',
        items: [
          {
            header: 'How do I make a booking?',
            content: '<ul><li>Step 1: Login to your account (If you have not registered, please register).</li><li>Step 2: Click <b>Find Hotel</b></li><li style="list-style: none;padding-left: 3.4em;">Enter the desired destination, check in and check out date, number of guests, guests’ country of residence, and then click search.</li><li>Step 3: Select the hotel you want to book.</li><li>Step 4: Select the preferred room, and click book.</li><li>Step 5: Double-check the cancellation policy and click proceed.</li><li>Step 6: Enter the guests’ details and click proceed to payment.</li><li>Step 7: Choose payment method and complete payment.</li><li>Step 8: Once booking is confirmed, you can choose to download the voucher or send confirmation email to you or to your customer whom you booked for.</li></ul>',
            slug: 'how-do-i-make-a-booking'
          },
          {
            header: 'Where is my booking confirmation?',
            content: '<p>You can view all your bookings, vouchers and cancellations from <b>Manage Bookings</b>.</p>',
            slug: 'where-is-my-booking-confirmation'
          },
          {
            header: 'Can I cancel the booking within the same day as the reservation is made without any charges?',
            content: `<p>This depends on the cancellation policy. Any charges due are on the account of the user. eBEDS is not liable and cannot make any promises in waiving fees. If you need assistance, please contact us by using the <b>chatbot</b> on the right or email the team at <a href="mailto:${this.$store.state.zumata.email}" class="has-text-secondary">${this.$store.state.zumata.email}</a> with your booking reference.</p>`,
            slug: 'cancel-booking-within-the-same-day'
          },
          {
            header: 'What is Pay at Hotel?',
            content: '<p><strong>Pay at Hotel</strong> is a type of room rate that offers more flexibility. Guest will need to enter the credit card information at checkout, but the credit card will only be charged upon the guest\'s check in. </p><p class="m-t">What are the benefits for you?</p><ul class="m-t"><li>Competitive, commissionable rates.</li><li>No payment upfront</li><li>Add extras to the booking direct with hotel.</li><li>Pay upon guest\'s check in</li><li>Agent / traveller can request invoice direct from hotel.</li></ul><p class="m-t">To find <strong>Pay at Hotel</strong> rates, you can look for the \'Pay at Hotel\' mark at the package, or filter room by \'Pay at Hotel\'.</p><br/><p><strong>Rates, payment and commissions:</strong></p><br/><p>Your account\'s pricing settings will not apply to any Pay at Hotel rates. The <strong>Pay at Hotel</strong> rate is <strong>always a Gross / commissionable rate</strong>(which you can quote direct to your client). Pay at Hotel rates will have a variable commission, depending on the property booked, which will be advised and paid back to you one month after check out date.</p>',
            slug: 'what-is-pay-at-hotel'
          },
          {
            header: 'How do I book a group?',
            content: `<p>For booking 9 rooms or more, please contact us by using the <b>chatbot</b> on the right or email the team at <a href="mailto:${this.$store.state.zumata.email}" class="has-text-secondary">${this.$store.state.zumata.email}</a>.</p>`,
            slug: 'book-a-group'
          },
          {
            header: 'The fare I booked is different than the charge on the credit card?',
            content: '<p>Please note that all transactions are done in either ZAR or USD. There may be a credit card transaction or forex fee added, depending on your account status.</p>',
            slug: 'different-charge-on-credit-card'
          },
          {
            header: 'I get an error when I try to book?',
            content: `<p>Due to the dynamic nature of hotel rate, prices and availability can change every minute. If you are getting an error, most likely the room is no longer available. Please try to book another room.  If you need any assistance please contact us by using the <b>chatbot</b> on the right or email the team at <a href="mailto:${this.$store.state.zumata.email}" class="has-text-secondary">${this.$store.state.zumata.email}</a>.</p>`,
            slug: 'error-when-booking'
          },
          {
            header: 'How can I change my booking?',
            content: '<p>All bookings are final and confirmed. If you wish to amend dates or hotel, we advise the following:</p><ul><li>Step 1: Check the cancellation policy carefully of your original booking to see if you can cancel free of charge.</li><li>Step 2: Leave your original booking as is and do a new search for the new dates or new hotel. If available, go ahead and confirm it as a new booking.</li><li>Step 3: Go back to the original booking and cancel. In this order.</li></ul>',
            slug: 'how-to-change-booking'
          },
          {
            header: 'How can I cancel my booking?',
            content: '<p>You can cancel your booking from <b>Manage Bookings</b>.  Please take note of the cancellation policy to be sure you are aware of any penalty that might apply.</p>',
            slug: 'how-to-cancel-booking'
          },
          {
            header: 'Can I get a refund for my cancelled bookings?',
            content: '<p>This depends on the cancellation policy of the individual booking.  Some bookings are non-refundable, and for these bookings you will not receive a refund. Other bookings offer the ability to cancel with either a partial or full refund.</p>',
            slug: 'cancelled-bookings-refund'
          },
          {
            header: 'What if I have a customer that needs special assistance?',
            content: '<p>Please leave a remark when booking the hotel. All special requests are subject to availability upon arrival.</p>',
            slug: 'customer-with-special-assistance'
          },
          {
            header: 'What are the booking statuses mean?',
            content: '<p>Here is the list of what each booking status means. If you are unsure about the status of your booking, please contact RTX support for assistance.</p><ul><li><b>Processing</b> - Order is created and being processed.</li><li><b>Pending</b> - Waiting for supplier to confirm the room reservation.</li><li><b>Payment in progress</b> - Waiting to authorize the payment.</li><li><b>Capture in progress</b> - Waiting to capture the payment.</li><li><b>On Hold</b> - Booking is reserved, but not yet paid. Please pay within the deadline or else the booking will be cancelled.</li><li><b>Confirmed</b> - Booking has been confirmed successfully.</li><li><b>Cancelled</b> - Booking has been cancelled successfully.</li><li><b>Failure</b> - Booking is failed. It could be do payment related error or technical error. Please try to make another booking.</li><li><b>Refund in progress</b> - Refund has been requested and waiting for confirmation.</li><li><b>Refund failure</b> - Error while doing a refund. Please contact RTX support for assistance.</li></ul>'
          }
        ]
      },
      finance: {
        title: 'Finance',
        items: [
          {
            header: 'How do I make payment?',
            content: '<p>There are 2 ways to make payment:<br><strong>Wallet:</strong> pay using prepaid balance from your electronic wallet. This option is faster and does not incur a transaction fee. In order to use this method, you need to top up your wallet first.<br><strong>Credit card:</strong> simply enter your credit card details when completing the checkout process. Please note that the credit card transaction charge is passed on to you.</p>',
            slug: 'how-to-make-payment'
          },
          {
            header: 'Is it safe to share credit card information on eBEDS?',
            content: '<p>Yes, we have multiple security steps to make sure the credit card information are not displayed and stored.</p>',
            slug: 'credit-card-information-shared'
          },
          {
            header: 'How to top up my wallet?',
            content: '<ul><li>Step 1: Your Wallet needs to be topped up in advance, before you start making bookings. On the top right, click on your name <strong>> My Account > Wallet</strong>. On the right you will see How to top up? Click this to get our banking details for ZAR and USD. <ul><li>Please ensure you use the correct account numbers and save the proof of payment.</li><li>Please note that we will not accept any bank charges. Kindly ensure that these are paid when making an electronic remittance. Any bank charges incurred will be deducted from the amount to be credited in your balance.</li></ul></li><li>Step 2: Once a payment has been made, click on <b>Request top up</b> and enter your bank account name, transferred amount, and upload the proof of payment. Then click Send Request. Review your request and click Ok.</li><li>Step 3: You will receive an email notification once the top up has been successful and will see the new balance on your Wallet. This could take a couple of days, so please plan accordingly.</li></ul>',
            slug: 'how-to-top-up-wallet'
          },
          {
            header: 'How do your prices compare to other suppliers?',
            content: '<p>In 2 ways: we have over 75 suppliers integrated into eBEDS, meaning we have access to many more contracts and rates. We also base our pricing on Net rates, meaning you have the freedom to mark up whatever amount you wish.</p>',
            slug: 'prices-compared-to-other-suppliers'
          },
          {
            header: 'How to stop receiving email about wallet balance reminder?',
            content: 'Go to <b>My Account > Wallet</b>, and untick the \'Email me daily balance updates.\'</p>',
            slug: 'how-to-stop-receiving-wallet-balance-reminder'
          },
          {
            header: 'What does 3D Secure / 3DS mean?',
            content: '<p>3D Secure or 3DS is a secure online payment service for credit cards. It ensures that a form of online identification is added to the authorization of a financial transaction with a credit card. The authentication procedure is simple and involves 3 steps.</p><ul><li>Place your booking and enter your credit card information.</li><li>If the security system is activated for your card, a 3D Secure™ window will open. Your bank will ask you to verify your identity by entering an authentication code. In most cases, this is a single-use security code that is sent to you by SMS on your mobile phone.</li><li>Once you enter the correct security code and your payment is accepted, your booking is confirmed.</li></ul><p>You can feel more confident knowing there is an extra level of security in place to protect your data. This extra security is free of charge and that there aren’t any additional fees hidden within the overall transaction.</p><p>Please contact your respective bank for more information on this service and the terms of use.</p>',
            slug: 'what-does-3d-secure-mean'
          }
        ]
      },
      account: {
        title: 'Account',
        items: [
          {
            header: 'How do I reset my password?',
            content: '<ul><li>Step 1: On the homepage, click on <b>Log in</b>.</li><li>Step 2: Click on <b>Forgot password</b>.</li><li>Step 3: Enter your email used to register with us and click on submit (If the email don’t arrive to your inbox check your spam box).</li><li>Step 4: Click the link in the email.</li><li>Step 5: Create a new password.</li></ul>',
            slug: 'how-to-reset-password'
          },
          {
            header: 'How do I add a user?',
            content: '<ul><li>Step 1: Go to <b>My Account</b> &gt; <b>Users</b></li><li>Step 2: Click on Invite User button.</li><li>Step 3: Fill in the name and email address of the new user. They will get an invitation email.</li><li>Step 4: The new user will have to click the link in the invitation email and register.</li></ul>',
            slug: 'how-to-add-user'
          },
          {
            header: 'How do I change my company details?',
            content: '<ul><li>Step 1: Go to <b>My Account</b> > <b>My Company</b></li><li>Step 2: Click on Edit on the top right corner.</li><li>Step 3: Edit the company details, and click Save.</li></ul>',
            slug: 'how-to-change-company-details'
          },
          {
            header: 'What is email template?',
            content: '<p>The info in email template will be used for contact details in confirmation and cancellation email sent to your guests.</p>',
            slug: 'what-is-email-template'
          }
        ]
      },
      general: {
        title: 'General',
        items: [
          {
            header: 'What device can I use eBEDS at?',
            content: '<p>Currently eBEDS is best viewed on desktop or laptop. We are working on a mobile version to be launched soon!</p>',
            slug: 'ebeds-supported-devices'
          },
          {
            header: 'Can my customers use eBEDS?',
            content: '<p>No, eBEDS travel agent portal is a B2B site that caters to travel agents. Only you and your colleagues can use eBEDS.</p>',
            slug: 'customers-to-use-ebeds'
          },
          {
            header: 'How do I change currency?',
            content: '<p>You can change the currency from the dropdown at <b>Find Hotel</b> and <b>Search result</b> page, and you can set your default currency from <b>My Account</b> > <b>Account Preference</b>.</p>',
            slug: 'how-to-change-currency'
          },
          {
            header: 'How are your prices lower than different websites?',
            content: '<p>We compare 75 suppliers to give you the best selection of global hotel and vacation rentals at the lowest prices.</p>',
            slug: 'ebeds-prices-than-different-websites'
          }
        ]
      },
      feedback: {
        title: 'Feedback',
        items: [
          {
            header: 'How can I send a feedback?',
            content: '<p>Please fill in our <a href="/feedback"  class="has-text-secondary">Feedback form</a> , and we will get back to you as soon as possible.</p>',
            slug: 'how-to-send-feedback'
          }
        ]
      },
      'contact-us': {
        title: 'Contact us',
        items: [
          {
            header: 'How to contact eBEDS?',
            content: `<p>Please contact us by using the <b>chatbot</b> on the right or email the team at <a class="has-text-secondary" href="mailto:${this.$store.state.zumata.email}">${this.$store.state.zumata.email}</a> </p>`,
            slug: 'how-to-contact-ebeds'
          }
        ]
      }
    }

    if (this.$route.hash !== '') {
      const slug = this.$route.hash.replace('#', '')

      const faq = this.getFaq(slug)

      if (faq !== undefined) {
        this.activeSectionKey = faq.parent
        this.activeName = faq.parent + '-' + faq.position
      }
    } else {
      this.setHash('am-i-eligible-for-an-account')
    }
  },
  methods: {
    handleSubmit () {
      if (this.searchResult) {
        this.activeSectionKey = this.searchResult.parent
        this.activeName = this.searchResult.parent + '-' + this.searchResult.suggestionIndex

        this.setHash(this.searchResult.slug)
      } else if (this.localForm.search) {
        this.querySearch(this.localForm.search, (results) => {
          if (results && results.length && results[0].tabIndex >= 0) {
            this.searchResult = results[0]
            this.activeSectionKey = this.searchResult.parent
            this.activeName = this.searchResult.parent + '-' + this.searchResult.suggestionIndex
          }
        })
      }
    },
    changeActiveSection (key) {
      this.activeSectionKey = key
      this.activeName = key + '-0'

      // set hash as the first item in the section
      this.setHash(this.faq[key].items[0].slug)
    },
    getFaq (slug) {
      for (const [ section, sectionItem ] of Object.entries(this.faq)) {
        const position = findIndex(sectionItem.items, { slug })
        const faq = sectionItem.items[position]

        if (faq !== undefined) {
          return {
            parent: section,
            position,
            ...faq
          }
        }
      }
    },
    setHash (slug) {
      this.$router.replace(`/faq#${slug}`)
    },
    querySearch (queryString, cb) {
      const suggestions = this.suggestions

      let results

      if (queryString) {
        results = suggestions.filter((suggestion) => {
          return (suggestion.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1)
        })

        if (results.length === 0) {
          results = [
            {
              value: '',
              label: this.$t('app.no-result'),
              tabIndex: -1,
              suggestionIndex: -1
            }
          ]
        }
      } else {
        results = suggestions
      }

      cb(results)
    },
    handleSelect (item) {
      if (item.tabIndex > -1) {
        this.searchResult = item
        this.handleSubmit()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../styles/bulma-variables";

  .faq-suggestion-item {
    white-space: normal;
    line-height: 1.5;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  // Scrolls up when FAQ is clicked, sometimes until it is unable to see the answer
  .faq-page .main-content {
   min-height: 900px;
  }
</style>
