<template>
  <div class="about-page">
    <div class="section is-vertically-large has-background-primary has-text-white about-banner">
      <div
        class="container is-tablet"
        style="padding-top: 21px;padding-bottom: 21px;">
        <h1 class="title is-size-3 m-b  has-text-white has-text-centered">
          About Us
        </h1>
      </div>
    </div>
    <div class="section is-vertically-large has-background-white">
      <div class="container is-tablet">
        <div class="columns is-vcentered m-b-12x m-t-0">
          <div class="column is-8">
            <p>
              Formed in 2020 in Cape Town... they told us we were mad. Due to the pandemic and its impact on the world, we learned to adapt very quickly. We are an innovative and technology-forward operation with an ‘Africa first’ approach. Our team has over 25+ years of industry experience from various backgrounds such as Totalstay, LOH (Webbeds), Hotelbeds and Booking.com.<br>
              <br>
              We believe in helping travel businesses grow—by providing them with the right platforms, tools, and expertise. All while embracing the latest technology. That is why we've created a business—to provide acclaimed software with a reliable community built around it, so we can help businesses grow better every day.
            </p>
          </div>
          <div class="column is-4">
            <img
              src="../../assets/cape-town.jpg"
              alt="">
          </div>
        </div>
        <h2 class="has-text-black has-text-weight-bold is-size-4 has-text-centered m-b-4x">
          Our Story
        </h2>
        <p class="m-b-4x">
          Starting out at a small travel start-up in London back in 2006 taught us how dynamic this industry is and how technology can help define it. With this experience, we set out to apply these technology principles back in South Africa.
        </p>
        <div class="columns is-vcentered m-b-4x">
          <div class="column is-4">
            <img
              src="../../assets/africa-map.png"
              alt="">
          </div>
          <div class="column is-8">
            <p>
              Travel behaviour and booking patterns in Africa are quite unique with its own set of difficulties and opportunities. Whether it’s high barriers to entry (cost, visas, etc) or the way travel is booked (mobile, travel agents, packages) we have our own ways of going about travel compared to the rest of the world. That is why it helps to have a local partner on board.<br>
              <br>
              From this perspective, our first company was born: Viva Connect. We consulted travel companies and helped them embrace technology. A couple of years later (during the height of Covid-19), eBEDS was launched as an accommodation provider to the African travel industry. It was founded on the notion that not only do we want to make access to travel supply and hotel distribution easier, we want to help our customers engage with their clients, adopt new technologies and thrive. After all, true value is built by giving <span class="has-text-underline">your</span> clients the most efficient and positive experience.
            </p>
          </div>
        </div>
        <p>As the saying goes, our clients’ success is our success. But especially coming out of 2020, our clients’ success is more important than ever for our industry. <strong>We fully support local; we hope you do too.</strong></p>
      </div>
    </div>
    <div
      class="section is-vertically-large section-4-bg"
      style="margin-bottom: -32px;">
      <div
        class="container is-tablet has-text-centered"
        style="padding-top: 30px;padding-bottom: 30px;">
        <h2 class="has-text-white is-size-4 has-text-weight-bold has-text-centered m-b-2x">
          Our Platform
        </h2>
        <p class="has-text-white m-b-4x">
          The eBEDS booking portal gives you access to all your favourite suppliers in one, easy to use platform—with tons of added benefits that help you service your clients better.
        </p>
        <!-- TEMP: hide register entrance -->
        <!-- <el-button
          class="button-secondary m-r-4x"
          style="min-width: 180px;"
          @click="$router.push('/register')">
          Register Now
        </el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import mixHead from '@/mixins/mixHead'
export default {
  name: 'AboutUs',
  mixins: [mixHead],
  data () {
    return {
      seo: {
        title: 'About Us | eBEDS',
        description: 'Our user-friendly and powerful portal enables you to book hotels for your clients at the lowest rate available. All bookings are backed by 24/7 customer support.',
        url: `${this.$store.state.baseUrl}/about-us`,
        image: `${this.$store.state.baseUrl}/og_image_whyus.jpg`
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../styles/bulma-variables";

  .about-banner {
    position: relative;
    //background: url("../../assets/about-banner.jpg") center bottom no-repeat;
    background-size: auto auto;
    // min-height: 500px;
    display: flex;
    align-items: center;
  }

  .section-4-bg {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(../../assets/ebeds-dashboard-screen.jpg) center center no-repeat, #F7F7F7;
    background-size: cover;

  }

</style>
