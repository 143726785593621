<template>
  <div class="terms-page">
    <div class="section is-vertically-large">
      <div class="container is-tablet">
        <StaticVersion />
      </div>
      <TouContent />
    </div>
  </div>
</template>

<script>
import StaticVersion from '@/components/StaticVersion'
import TouContent from '@/components/TouContent'

export default {
  name: 'TermsOfUse',
  components: { StaticVersion, TouContent },
  metaInfo () {
    return {
      title: 'Terms of use'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/bulma-variables";

</style>
