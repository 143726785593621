<template>
  <div class="terms-page">
    <div class="section is-vertically-large">
      <div class="container is-tablet">
        <StaticVersion />
      </div>
      <div
        v-if="$i18n.locale==='zh-CN'"
        class="container is-tablet">
        <h1 class="is-size-3 has-text-weight-bold has-text-primary  m-b-5x">
          隐私政策
        </h1>
        <div class="content">
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            介绍
          </h3>
          <p>
            eBEDS承认其根据《个人数据保护法》（“PDPA”）在收集、使用、披露、存储和传输个人数据方面的责任。<br><br>
            个人资料只会为合法及相关用途而收集，并会采取合理步骤，确保eBEDS所持有的个人资料准确无误。eBEDS将采取合理措施保护个人数据，避免未经授权的访问。
          </p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            客户
          </h3>
          <p>
            我们eBEDS将客户的隐私利益放在优先考虑的位置。我们致力于保护提供给我们的个人资料的隐私、机密性、准确性和安全性。我们的隐私政策告诉您我们如何收集、使用、披露、存储和传输您的个人数据。我们要求所有员工在进行日常活动时遵守我们的隐私政策。<br><br>
            我们收集的个人资料<br><br>
            根据《个人数据保护法》，个人数据被定义为有关个人的信息，该个人可以从该数据中识别；或者从该数据和组织有权或可能有权访问的其他信息中识别。收集到的关于您和您的家属的个人数据可能包括：
          </p>
          <ol>
            <li>姓名、电子邮件、地址和电话号码、年龄、出生日期、性别</li>
            <li>身份证，护照，财务编号。</li>
          </ol>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            我们如何使用您的个人资料（目的和通知义务）
          </h3>
          <p>我们可以收集和使用您的个人数据：</p>
          <ul>
            <li>确定你的身份</li>
            <li>与你有效沟通</li>
            <li>提供产品推荐</li>
            <li>为您提供持续服务并回应您的询问或指示</li>
            <li>进行研究和统计分析</li>
            <li>遵守所有适用法律，包括向监管和行业实体报告</li>
          </ul>
          <p>
            我们只会以公开、公平及合法的方式收集及使用个人资料，而该等方式是为上述目的所必需的。<br><br>
            向他人发布数据（转出）<br><br>
            我们可以向第三方披露您的个人数据：
          </p>
          <ul>
            <li>服务提供商为我们提供打印、邮件分发、数据存储等服务</li>
            <li>监管机构、执法机构和政府机构</li>
          </ul>
          <p>我们只会披露您的个人资料，因为它是为上述目的合理要求的，而且该资料仅限于我们和这些第三方进行工作所必需的信息。这些当事人还必须遵守新加坡个人资料保护法。</p>
          <p>我们可以将个人数据传输给新加坡境外的服务提供商，以便进行处理、存储、分析、灾难恢复或紧急援助服务，并且可能需要将此数据披露给该外国适用法律授权的人员。我们与之有合同关系的这些服务提供商必须为传输的个人数据提供一个与《新加坡个人数据保护法》下的保护相当的保护标准，并符合我们的个人数据保护政策和实践。</p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            撤回你的许可
          </h3>
          <p>只要没有法律或合同限制阻止您收集、使用或披露您的个人数据，您可以通过向我们发出合理通知来拒绝或撤回您对我们收集、使用或披露您的个人数据的许可。</p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            将信息用于营销目的
          </h3>
          <p>我们不会将您提供的信息用于营销目的。</p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            准确度
          </h3>
          <p>我们将尽一切合理努力确保我们或代表我们收集的您的个人资料是准确和完整的。</p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            您的访问和更正权利
          </h3>
          <p>
            根据PDPA，您有权要求访问我们拥有的您的任何个人数据，并在法律允许的范围内了解过去12个月内您的个人数据是如何使用和披露的。但是，您可能会为每次访问请求支付合理的费用。<br><br>
            你也有权要求更正你的个人资料。
          </p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            保护您的个人数据（安全）
          </h3>
          <p>
            我们通过适当的物理、技术和组织保护措施，保护您的个人数据免受未经授权的访问、收集、使用、披露、复制、修改或处置。<br><br>
            我们所有代表我们行事的员工和服务提供商必须遵守我们的隐私政策和惯例，并且只能访问您的数据来完成他们的工作。
          </p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            保留
          </h3>
          <p>我们将只保留您的个人资料，只要它是为其收集的目的和为商业或法律目的所需要的。一旦您的个人资料不再需要这些用途，我们将销毁，删除或匿名的数据。</p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            联系我们
          </h3>
          <p>
            如果您对我们的个人数据保护政策和做法有任何问题、疑问或投诉，或希望请求访问、更新或更正您的个人数据，请将您的疑问发送至 <a
              :href="`mailto:${$store.state.zumata.email}`"
              class="has-text-secondary">{{ $store.state.zumata.email }}</a>
          </p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            此隐私策略的更改
          </h3>
          我们会定期检查本私隐政策，并保留随时作出更改的权利，以顾及我们的业务和法律要求的更改。<br><br>
          我们将在我们的网站上更新和修订隐私政策。如果这些更改是重大的，并降低了您在本隐私政策下的权利，我们将在本网站上通知您。任何更改将在本隐私政策修订并公布于本网站之日起30天内生效。
        </div>
      </div>
      <div
        v-else
        class="container is-tablet">
        <h1 class="is-size-3 has-text-weight-bold has-text-primary  m-b-5x">
          Privacy Policy
        </h1>
        <div class="content">
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            Introduction
          </h3>
          <p>
            eBEDS recognizes its responsibilities in relation to the collection, usage, disclosure, storage and transfer of personal data under the Personal Data Protection Act (“PDPA”).<br><br>
            Personal data will be collected only for lawful and relevant purposes and reasonable steps will be taken to ensure that personal data held by eBEDS is accurate. eBEDS will take reasonable steps to protect the personal data and to avoid unauthorized access.
          </p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            Customers
          </h3>
          <p>
            We at eBEDS place the privacy interests of our customers high in our priorities. We are committed to protecting the privacy, confidentiality, accuracy and security of the personal data provided to us. Our Privacy Policy tells you how we collect, use, disclose, store and transfer your personal data. All our employees are required to comply with our Privacy Policy when they carry out their daily activities.<br><br>
            Personal Data We Collect<br><br>
            Under PDPA, personal data is defined as information about an individual who can be identified from that data; or from that data and other information to which the organization has or is likely to have access. Personal data collected about you and your dependents may include:
          </p>
          <ol>
            <li>Name, email, address and telephone number, age, date of birth, gender</li>
            <li>NRIC, Passport, FIN No.</li>
          </ol>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            How we use your personal data (Purpose & Notification Obligation)
          </h3>
          <p>We may collect and use your personal data to:</p>
          <ul>
            <li>identify you</li>
            <li>communicate effectively with you</li>
            <li>provide product recommendation</li>
            <li>provide you with ongoing services and respond to your inquiries or instructions</li>
            <li>conduct research and statistical analysis</li>
            <li>comply with all applicable laws, including reporting to regulatory and industry entities</li>
          </ul>
          <p>
            We will only collect and use personal data in an open, fair and lawful way that is necessary for the purposes identified by us above.<br><br>
            Releasing Your Data to Others (Transfer Out)<br><br>
            We may disclose your personal data to third parties:
          </p>
          <ul>
            <li>service providers to provide us with services such as printing, mail distribution, data storage</li>
            <li>regulators, law enforcement and government agencies</li>
          </ul>
          <p>We will only disclose your personal data as it is reasonably required for the purposes stated above and the data is limited to information necessary for us and these third parties to do the work. These parties are also required to comply with the Singapore Personal Data Protection Act.</p>
          <p>We may transfer personal data to service providers outside of Singapore for processing, storage, analysis, disaster recovery or emergency assistance services and this data may be required to be disclosed to those authorized under the applicable laws of that foreign country. These service providers with whom we have contractual relationships are required to provide a standard of protection to the transferred personal data that is comparable to the protection under the Singapore Personal Data Protection Act and consistent with our personal data protection policies and practices.</p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            Withdrawing Your Consent
          </h3>
          <p>You may refuse or withdraw your consent for us to collect, use or disclose your personal data by giving us reasonable notice so long as there are no legal or contractual restrictions preventing you from doing so.</p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            Using Information for Marketing Purposes
          </h3>
          <p>The information you supply will not be used by us for marketing purposes.</p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            Accuracy
          </h3>
          <p>We will take all reasonable efforts to ensure that your personal data collected by us or on our behalf is accurate and complete.</p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            Your Rights of Access & Correction
          </h3>
          <p>
            Under the PDPA, you have the right to request access to any personal data of yours that we have, and know how it is being used and disclosed for the last 12 months to the extent your right is allowed by law. However, you may be charged a reasonable fee for every request for such access.<br><br>
            You also have the right to request correction of your personal data.
          </p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            Protecting Your Personal Data (Security)
          </h3>
          <p>
            We protect your personal data from unauthorized access, collection, use, disclosure, copying, modification or disposal by having physical, technological and organizational safeguards in place that are appropriate to the sensitivity of the data.<br><br>
            All our staff and service providers who act on our behalf must comply with our privacy policies and practices and can only access your data to do their jobs.
          </p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            Retention
          </h3>
          <p>We will keep your personal data only for as long as it is needed for the purposes for which it was collected and as required for business or legal purposes. Once your personal data is no longer required for these purposes, we will destroy, erase or make anonymous the data.</p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            Contacting Us
          </h3>
          <p>
            If you have any concern, query or complaint about our personal data protection policies and practices or wish to request access to, update or correct your personal data, please send your query to  <a
              :href="`mailto:${$store.state.zumata.email}`"
              class="has-text-secondary">{{ $store.state.zumata.email }}</a>
          </p>
          <h3 class="is-size-5 has-text-primary has-text-weight-bold m-b">
            Changes to this Privacy Policy
          </h3>
          We review this Privacy Policy regularly and reserve the right to make changes at any time to take into account of changes in our business and legal requirements.<br><br>
          We will place updates and our revised Privacy Policy on our website. If the changes are significant and reduces your rights under this Privacy Policy, we will notify you on this website. Any changes will be effective 30 days following the date on which this Privacy Policy is revised and posted on our website.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StaticVersion from '@/components/StaticVersion'
export default {
  name: 'PrivacyPolicy',
  components: { StaticVersion },
  metaInfo () {
    return {
      title: 'Privacy Policy'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/bulma-variables";

</style>
